import React from 'react'
import { graphql, Link } from 'gatsby'
import styled from 'styled-components'
import { PortableText } from '@portabletext/react'
import { FaFilePdf } from 'react-icons/fa'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import Seo from '../components/Seo'
import useMediaQuery from '../hooks/use-media-query'

const dateOptions = {
  day: 'numeric',
  month: 'long',
  year: 'numeric'
}

const getLearningMethodThemeColor = (factsheetTitle, methodsData) => {
  factsheetTitle = factsheetTitle.replace(' Factsheet', '')

  const learningMethod = methodsData.find(method => {
    const methodTitle = method.title.replace(' Courses', '').replace(' ', '-')
    return methodTitle === factsheetTitle
  })

  return learningMethod?.themeColour || '#B50E20'
}

// === MAIN COMPONENT START ===

const CoursePage = ({ data, pageContext }) => {
  const {
    title,
    slug,
    featuredImage,
    featuredVideoURL,
    description,
    _rawDescription,
    courseInformation,
    courseStartDates,
    // programmeTypes,
    // accreditation,
    factsheets,
    studyFields,
    seo
  } = data.sanityCourse

  const learningMethodsData = data.allSanityLearningMethod.nodes
  const studyFieldContext = pageContext.studyField

  const mediaQueryMatches = useMediaQuery('(max-width: 768px)')

  // === Various Elements ===
  const learningMethodTagElements = (
    <div className='tags-container'>
      {courseStartDates.map(({learningMethod}) => (
        <span key={learningMethod.title} className='learning-method-tag' style={{backgroundColor: learningMethod.themeColour}}>
          {learningMethod.title.replace(' Courses', '')}
        </span>
      ))}
    </div>
  )

  const courseInfoElements = courseInformation.map(infoItem => (
    <details key={infoItem.title}>
      <summary>{infoItem.title}</summary>
      <div className="details-content">
        <PortableText value={infoItem._rawDetails} />
      </div>
    </details>
  ))

  const courseStartDateElements = courseStartDates.map(({learningMethod, startDate, duration, classTimes}) => (
    <div key={learningMethod.title} className='course-instance'>
      <h3 style={{backgroundColor: learningMethod.themeColour}}>{learningMethod.title.replace(' Courses', '')}</h3>
      <div className='course-instance-content'>
        <p><strong>Start Date:</strong> {new Date(startDate).toLocaleDateString('en-ZA', dateOptions)}</p>
        <p><strong>Duration:</strong> {duration.toLowerCase()}</p>
        {learningMethod.title !== 'Online Courses' && <p><strong>Classes:</strong> {classTimes}</p>}
      </div>
    </div>
  ))

  const factsheetElements = factsheets.map(({ title, asset }) => (
    <a 
      key={title} 
      href={asset.url} 
      style={{ backgroundColor: getLearningMethodThemeColor(title, learningMethodsData) }} 
      className="factsheet" 
      target='_blank' 
      rel='noreferrer'
    >
      <FaFilePdf /> {title}
    </a>
  ))

  const featuredVideoElement = (
    <iframe
      width="560" 
      height="315" 
      src={featuredVideoURL?.replace('youtu.be', 'www.youtube.com/embed')} 
      title="YouTube video player" 
      frameBorder="0" 
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
      allowFullScreen 
    />
  )

  // const additionalInfoElements = (
  //   <section className="additional-info">
  //     <p>
  //       <strong>Programme Type{programmeTypes.length > 1 && 's'}: </strong> 
  //       {programmeTypes.join(', ')}
  //     </p>
  //     <p>
  //       <strong>Accreditation{accreditation.length > 1 && 's'}: </strong> 
  //       {accreditation.map(accr => accr.title).join(', ')}
  //     </p>
  //   </section>
  // )

  // === Course Page Templates ===
  const CourseTemplateLarge = () => (
    <div className='course-template'>
      <section>
        <section className="content">
          <PortableText value={_rawDescription} />

          {courseInfoElements}
          {factsheets && factsheetElements}
        </section>
      </section>
      <aside>
        <GatsbyImage image={getImage(featuredImage.asset)} alt={title} />

        <div className='contact-buttons'>
          <Link to='/contact/' className='btn-contact'>Enquire Now</Link>
          <Link to='/registration/' className='btn-contact'>Register</Link>
        </div>

        {/* {additionalInfoElements} */}
        {courseStartDateElements}
        {featuredVideoURL && featuredVideoElement}
      </aside>
    </div>
  )

  const CouresTemplateSmall = () => (
    <section>
      <GatsbyImage image={getImage(featuredImage.asset)} alt={title} />
      <PortableText value={_rawDescription} />

      {courseStartDateElements}

      {/* {additionalInfoElements} */}

      <section id='course-info'>
        {courseInfoElements}
      </section>

      {factsheets && factsheetElements}
      <div className='contact-buttons'>
        <Link to='/contact/' className='btn-contact'>Enquire Now</Link>
        <Link to='/registration/' className='btn-contact'>Register</Link>
      </div>

      {featuredVideoURL && featuredVideoElement}
    </section>
  )

  // === Meta Keywords ===
  const metaKeywords = [...seo?.focus_synonyms ? seo.focus_synonyms : []]

  if (seo?.focus_keyword) {
    metaKeywords.unshift(seo.focus_keyword)
  }

  // === MAIN RETURN ===
  return (
    <StyledCoursePage>
      <Seo 
        title={seo?.seo_title || title} 
        description={seo?.meta_description || description.map(item => item.children[0]?.text)[0].substring(0, 150)}
        keywords={metaKeywords}
        image={{
          src: featuredImage.asset.gatsbyImageData.images.fallback.src,
          height: featuredImage.asset.gatsbyImageData.height,
          width: featuredImage.asset.gatsbyImageData.width,
        }}
        pathname={`/${studyFields[0].slug.current}/${slug.current}`}
      />

      <div className='heading-container'>
        <h1>{title}</h1>
        {learningMethodTagElements}
      </div>
        
      <p className="breadcrumbs">
        <Link to='/'>Home</Link> {`>`}&nbsp;
        <Link to={`/study-fields/${studyFieldContext.slug.current}/`}>{studyFieldContext.title}</Link> {`>`}&nbsp;
        {title}
      </p>

      {mediaQueryMatches ? <CouresTemplateSmall /> : <CourseTemplateLarge />}
    </StyledCoursePage>
  )
}

export const query = graphql`
  query CouresQuery($id: String) {
    sanityCourse(id: { eq: $id }) {
      title
      slug { current }
      featuredImage {
        asset { gatsbyImageData(width: 1200) }
      }
      featuredVideoURL
      description {
        children { text }
      }
      _rawDescription
      courseInformation {
        title
        _rawDetails
      }
      courseStartDates {
        learningMethod {
          title
          themeColour
        }
        startDate
        duration
        classTimes
      }
      programmeTypes
      accreditation {
        title
      }
      factsheets {
        title
        asset { url }
      }
      studyFields {
        slug { current }
      }
      seo {
        seo_title
        meta_description
        focus_keyword
        focus_synonyms
      }
    }
    allSanityLearningMethod {
      nodes {
        title
        themeColour
      }
    }
  }
`

const StyledCoursePage = styled.main`
  max-width: 1500px;
  margin: auto;
  padding: 6rem 4rem 4rem 4rem;

  div.heading-container {
    display: flex;
    flex-flow: row wrap;
    gap: 1.5rem;
    align-items: center;
  }

  div.tags-container {
    display: flex;
    flex-flow: row wrap;
    gap: 0.75rem;

    & > * { flex: 0 0 auto; }

    span.learning-method-tag {
      display: inline-block;
      color: #f4f4f4;
      padding: 0.25em 0.75em;
      border-radius: 50px;
    }
  }

  a {
    color: var(--primary-color);
    text-decoration: none;
  }

  h1 {
    display: inline-block;
    margin-bottom: 0.5rem;
    font-weight: 600;
  }

  h2 {
    margin-bottom: 1rem;
    font-size: 1.4rem;
    font-weight: 600;
  }

  p:not(.breadcrumbs) {
    margin-bottom: 1rem;
    line-height: 1.4;
  }

  p.breadcrumbs {
    margin-bottom: 1.9rem;
  }

  ul, ol {
    list-style: inside;
    margin-bottom: 1.2rem;

    li {
      line-height: 1.6;
    }
  }

  img {
    padding-bottom: 1rem;
  }

  div.contact-buttons {
    display: flex;
    gap: 1rem;
    margin: 1rem 0 2rem 0;
    
    a.btn-contact {
      flex: 1 1 auto;
      display: inline-block;
      background-color: #6c757d;
      color: #f4f4f4;
      padding: 0.6em 1.4em;
      font-size: 0.9rem;
      font-weight: bold;
      letter-spacing: 1px;
      text-align: center;
      text-transform: uppercase;
    }
  }

  details {
    margin-bottom: 1.25rem;
    position: relative;

    &[open] {
      summary {
        
      }
    }

    summary {
      background-color: #eee;
      padding: 0.75rem 1.5rem;
      cursor: pointer;
      position: relative;

      &::marker {
        
      }
    }

    div.details-content {
      padding: 1rem;
    }
  }

  section.additional-info {
    font-size: 0.85rem;

    /* *:not(strong) {
      font-weight: 300;
    } */
  }

  div.course-instance {
    margin-bottom: 2rem;
    
    h3 {
      color: #fafafa;
      margin-bottom: 1rem;
      padding: 0.4em 0.8em;
      font-weight: 400;
      border-radius: 50px;
    }

    p {
      margin: 0.4rem 0;
    }

    div.course-instance-content {
      display: grid;
      grid-template-columns: 1fr 1fr;

      *:first-child {
        grid-column: 1 / -1;
      }
    }
  }

  a.factsheet {
    display: inline-block;
    background-color: var(--primary-color);
    color: #f4f4f4;
    margin: 0 1rem 1.4rem 0;
    padding: 0.75rem 1rem;
    text-decoration: none;
    border-radius: 50px;
  }

  iframe[title='YouTube video player'] {
    display: block;
    width: 100%;
    max-width: 560px;
    height: auto;
    margin: 2rem 0;
  }

  @media (max-width: 768px) {
    padding: 3rem 1rem 1rem 1rem;

    div.heading-container {
      display: initial;
    }

    h1 {
      font-size: 1.75rem;
    }

    div.tags-container {
      margin-bottom: 1rem;
    }

    p.breadcrumbs {
      display: none;
    }

    section#course-start-dates, 
    section#course-info {
      margin-top: 2rem;
    }
  }

  @media (min-width: 769px) {
    div.course-template {
      display: grid;
      grid-template-columns: 1fr 350px;
      grid-gap: 3rem;
    }
  }
`

export default CoursePage
